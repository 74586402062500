<template>
  <div>
    <v-dialog
      v-model="confirmDialog"
      max-width="290"
      @click:outside="confirmDialog = false"
    >
      <v-card>
        <v-card-title>
          {{ confirmTitle }}
        </v-card-title>
        <v-card-text>
          {{ confirmMsg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <slot name="cancel-btn">
            <v-btn @click="confirmDialog = false" text>
              {{ $t("cancel") }}
            </v-btn>
          </slot>
          <slot name="confirm-btn">
            <v-btn
              color="primary"
              @click="
                confirmDialog = false;
                confirmMethod();
              "
              text
            >
              {{ $t("confirm") }}
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSensorsData" width="80%" scrollable>
      <v-card>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ componentName }} - {{ $t("sensors data") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table class="my-2" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ $t("type") }}</th>
                  <th>{{ $t("name") }}</th>
                  <th>{{ $t("value") }}</th>
                  <th>{{ $t("unit") }}</th>
                  <th>{{ $t("event") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rec in sensorsData" :key="rec.id">
                  <td class="text-left text-no-wrap">{{ rec.type }}</td>
                  <td class="text-left text-no-wrap">{{ rec.name }}</td>
                  <td class="text-right monospace">
                    {{ rec.reading != null ? rec.reading : "-" }}
                  </td>
                  <td class="text-left text-no-wrap">{{ rec.units }}</td>
                  <td class="text-left">{{ rec.event }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showSensorsData = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSetupAccess" width="60%" scrollable>
      <validation-observer
        ref="obsSetupAccess"
        v-slot="{ errors, invalid, validated }"
      >
        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ componentName }} - {{ $t("access settings") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="username"
                  :name="$t('username')"
                  rules="required|max:100"
                  v-slot="{ errors, classes, dirty, valid }"
                >
                  <v-text-field
                    v-model="accessUsername"
                    name="username"
                    :label="$t('username')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="password"
                  :name="$t('password')"
                  rules="required|max:100"
                  v-slot="{ errors, classes, dirty, valid }"
                >
                  <v-text-field
                    v-model="accessPassword"
                    name="password"
                    :label="$t('password')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showSetupAccess = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              @click="changeAccessSettings"
              :loading="loadingAccessSettings"
              :disabled="invalid || loading || loadingAccessSettings"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>

    <v-menu offset-y rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          class="mr-1 mb-1"
          :loading="loading"
          :class="{
            'success--text':
              statusPowerStatus != null && statusPowerStatus == 'on',
            'warning--text':
              statusPowerStatus != null && statusPowerStatus == 'off',
            'error--text':
              statusPowerStatus != null && statusPowerStatus == 'error',
          }"
          outlined
          rounded
          small
        >
          <v-icon left small> mdi-toy-brick-outline </v-icon>
          {{ componentName }}
          <div v-if="statusPowerStatus != null">/ {{ statusPowerStatus }}</div>
        </v-btn>
      </template>
      <v-list max-width="280" dense>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="2">{{ $t("URL") }}</v-col>
                <v-col cols="8" class="monospace">
                  <div id="frontend_url">
                    {{ value.frontend_url }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    x-small
                    @click="copyTextToClipboard(value.frontend_url)"
                  >
                    <v-icon x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="2">{{ $t("IP") }}</v-col>
                <v-col cols="8" class="monospace">
                  <div id="ip4">{{ value.ip4 }}</div>
                </v-col>
                <v-col cols="2">
                  <v-btn icon x-small @click="copyTextToClipboard(value.ip4)">
                    <v-icon x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="2">{{ $t("MAC") }}</v-col>
                <v-col cols="8" class="monospace">
                  <div id="mac">{{ value.mac }}</div>
                </v-col>
                <v-col cols="2">
                  <v-btn icon x-small @click="copyTextToClipboard(value.mac)">
                    <v-icon x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item :href="value.frontend_url" target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("open in browser") }}
          </v-list-item-title>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item @click="sensors" :disabled="loadingSensors">
          <v-list-item-icon>
            <v-icon>mdi-thermometer</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("read sensors") }}
          </v-list-item-title>
        </v-list-item>
        <div v-if="!staffOnly">
          <v-divider class="my-2" />
          <v-list-item
            @click="showSetupAccess = true"
            :disabled="loadingAccessSettings"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("setup access") }}
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-divider class="my-2" />
        <v-list-item @click="powerStatus" :disabled="loadingPowerStatus">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power status") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasAccess"
          @click="
            confirmMethod = powerOn;
            confirmTitle = $t('power on');
            confirmMsg = $t('Do you really want to run {cmd}?', {
              cmd: $t('power on'),
            });
            confirmDialog = true;
          "
          :disabled="loadingPowerOn || statusPowerStatus == 'on'"
          :class="{ 'staff--text': staffOnly }"
        >
          <v-list-item-icon>
            <v-icon :color="staffOnly ? 'staff' : ''">mdi-power-on</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power on") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasAccess"
          @click="
            confirmMethod = powerCycle;
            confirmTitle = $t('power cycle');
            confirmMsg = $t('Do you really want to run {cmd}?', {
              cmd: $t('power cycle'),
            });
            confirmDialog = true;
          "
          :disabled="loadingPowerCylcle"
          :class="{ 'staff--text': staffOnly }"
        >
          <v-list-item-icon>
            <v-icon :color="staffOnly ? 'staff' : ''">mdi-power-cycle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power cycle") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasAccess"
          @click="
            confirmMethod = powerOff;
            confirmTitle = $t('power off');
            confirmMsg = $t('Do you really want to run {cmd}?', {
              cmd: $t('power off'),
            });
            confirmDialog = true;
          "
          :disabled="loadingPowerOff || statusPowerStatus != 'on'"
          :class="{ 'staff--text': staffOnly }"
        >
          <v-list-item-icon>
            <v-icon :color="staffOnly ? 'staff' : ''">mdi-power-off</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power off") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasAccess"
          @click="
            confirmMethod = powerOffSoft;
            confirmTitle = $t('power off soft');
            confirmMsg = $t('Do you really want to run {cmd}?', {
              cmd: $t('power off soft'),
            });
            confirmDialog = true;
          "
          :disabled="loadingPowerOffSoft || statusPowerStatus != 'on'"
          :class="{ 'staff--text': staffOnly }"
        >
          <v-list-item-icon>
            <v-icon :color="staffOnly ? 'staff' : ''">mdi-power-sleep</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power off soft") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="hasAccess"
          @click="
            confirmMethod = powerReset;
            confirmTitle = $t('power reset');
            confirmMsg = $t('Do you really want to run {cmd}?', {
              cmd: $t('power reset'),
            });
            confirmDialog = true;
          "
          :disabled="loadingPowerReset"
          :class="{ 'staff--text': staffOnly }"
        >
          <v-list-item-icon>
            <v-icon :color="staffOnly ? 'staff' : ''">mdi-knob</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("power reset") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import copyToClipboard from "@/utils/mixins/copyToClipboard";

export default {
  name: "OobComponent",
  props: {
    value: {
      type: Object,
      required: true,
    },
    autoLoad: {
      type: Boolean,
      default: false,
    },
    staffOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isMobile, copyToClipboard],
  data: () => ({
    loadingComponent: false,
    loadingPowerCylcle: false,
    loadingPowerOff: false,
    loadingPowerOffSoft: false,
    loadingPowerOn: false,
    loadingPowerReset: false,
    loadingPowerStatus: false,
    loadingSensors: false,
    statusPowerStatus: null,
    sensorsData: [],
    showSensorsData: false,
    showSetupAccess: false,
    loadingAccessSettings: false,
    accessUsername: null,
    accessPassword: null,
    confirmDialog: false,
    confirmTitle: null,
    confirmMsg: null,
    confirmMethod: null,
  }),
  computed: {
    loading() {
      return (
        this.loadingComponent ||
        this.loadingPowerCylcle ||
        this.loadingPowerOff ||
        this.loadingPowerOffSoft ||
        this.loadingPowerOn ||
        this.loadingPowerReset ||
        this.loadingPowerStatus ||
        this.loadingSensors ||
        this.loadingAccessSettings
      );
    },
    componentName() {
      if (this.value) return this.value.name + " / " + this.value.ip4;
    },
    hasAccess() {
      return !this.staffOnly || this.$store.getters.isStaff;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value != null && this.autoLoad) {
          this.powerStatus();
          this.accessUsername = this.value.username || null;
          this.accessPassword = this.value.password || null;
        }
      },
    },
  },
  methods: {
    powerCycle() {
      var that = this;
      that.loadingPowerCylcle = true;
      this.$http
        .post("services/oob/ipmi-components/" + this.value.id + "/power-cycle")
        .then((response) => {
          that.$snotify.info(
            that.$i18n.t("{component_name} power cycle: {status}", {
              component_name: that.componentName,
              status: response.data.status,
            })
          );
          that.powerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingPowerCylcle = false;
        });
    },
    powerOff() {
      var that = this;
      that.loadingPowerOff = true;
      this.$http
        .post("services/oob/ipmi-components/" + this.value.id + "/power-off")
        .then((response) => {
          that.$snotify.info(
            that.$i18n.t("{component_name} power off: {status}", {
              component_name: that.componentName,
              status: response.data.status,
            })
          );
          that.powerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingPowerOff = false;
        });
    },
    powerOffSoft() {
      var that = this;
      that.loadingPowerOffSoft = true;
      this.$http
        .post(
          "services/oob/ipmi-components/" + this.value.id + "/power-off-soft"
        )
        .then((response) => {
          that.$snotify.info(
            that.$i18n.t("{component_name} power off soft: {status}", {
              component_name: that.componentName,
              status: response.data.status,
            })
          );
          that.powerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingPowerOffSoft = false;
        });
    },
    powerOn() {
      var that = this;
      that.loadingPowerOn = true;
      this.$http
        .post("services/oob/ipmi-components/" + this.value.id + "/power-on")
        .then((response) => {
          that.$snotify.info(
            that.$i18n.t("{component_name} power on: {status}", {
              component_name: that.componentName,
              status: response.data.status,
            })
          );
          that.powerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingPowerOn = false;
        });
    },
    powerReset() {
      var that = this;
      that.loadingPowerReset = true;
      this.$http
        .post("services/oob/ipmi-components/" + this.value.id + "/power-reset")
        .then((response) => {
          that.$snotify.info(
            that.$i18n.t("{component_name} power reset: {status}", {
              component_name: that.componentName,
              status: response.data.status,
            })
          );
          that.powerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingPowerReset = false;
        });
    },
    powerStatus() {
      var that = this;
      that.loadingPowerStatus = true;
      this.$http
        .post(
          "services/oob/ipmi-components/" + this.value.id + "/power-status",
          {},
          {
            disableDefaultErrorHandler: true,
          }
        )
        .then((response) => {
          if (!(that.statusPowerStatus == null && that.autoLoad))
            that.$snotify.info(
              that.$i18n.t("{component_name} power status: {status}", {
                component_name: that.componentName,
                status: response.data.status,
              })
            );
          that.statusPowerStatus = response.data.status;
        })
        .catch((err) => {
          that.$snotify.error(
            that.$i18n.t("{component_name}: {error}", {
              component_name: that.componentName,
              error: err.message,
            }),
            {
              timeout: 0,
            }
          );
          that.statusPowerStatus = "error";
        })
        .finally(function () {
          that.loadingPowerStatus = false;
        });
    },
    sensors() {
      var that = this;
      that.loadingSensors = true;
      this.$http
        .post("services/oob/ipmi-components/" + this.value.id + "/sensors")
        .then((response) => {
          that.sensorsData = response.data;
          that.showSensorsData = true;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingSensors = false;
        });
    },
    changeAccessSettings() {
      var that = this;
      that.loadingAccessSettings = true;
      this.$http
        .post(
          "services/oob/ipmi-components/" +
            this.value.id +
            "/change-username-password",
          {
            username: this.accessUsername,
            password: this.accessPassword,
          }
        )
        .then((response) => {
          that.$emit("input", response.data);
          that.showSetupAccess = false;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingAccessSettings = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "open in browser": "open in browser",
    "power cycle": "Power cycle",
    "power off": "Power off",
    "power off soft": "Power off soft",
    "power on": "Power on",
    "power reset": "Power reset",
    "power status": "Power status",
    "read sensors": "Read sensors",
    "setup access": "Setup access",
    "access settings": "Access Settings",
    "URL": "URL",
    "IP": "IP",
    "MAC": "MAC",
    "sensors data": "Sensors data",
    "type": "Type",
    "name": "Name",
    "value": "Value",
    "unit": "Unit",
    "event": "Event",
    "close": "Close",
    "cancel": "Cancel",
    "save": "Save",
    "confirm": "Confirm",
    "username": "Username",
    "password": "Passwort",
    "Do you really want to run {cmd}?": "Do you really want to run {cmd}?",
    "{component_name} power cycle: {status}": "{component_name} power cycle: {status}",
    "{component_name} power off: {status}": "{component_name} power off: {status}",
    "{component_name} power off soft: {status}": "{component_name} power off soft: {status}",
    "{component_name} power on: {status}": "{component_name} power on: {status}",
    "{component_name} power reset: {status}": "{component_name} power reset: {status}",
    "{component_name} power status: {status}": "{component_name} power status: {status}",
    "{component_name}: {error}": "{component_name}: {error}"
  },
  "de": {
    "open in browser": "im Browser öffnen",
    "power cycle": "Power Cycle",
    "power off": "Power Off",
    "power off soft": "Power Off Soft",
    "power on": "Power On",
    "power reset": "Power Reset",
    "power status": "Power Status",
    "read sensors": "Sensoren auslesen",
    "setup access": "Zugang einrichten",
    "access settings": "Zugangseinstellungen",
    "URL": "URL",
    "IP": "IP",
    "MAC": "MAC",
    "sensors data": "Sensoren Daten",
    "type": "Typ",
    "name": "Name",
    "value": "Wert",
    "unit": "Einheit",
    "event": "Ereignis",
    "close": "Schliessen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "confirm": "Bestätigen",
    "username": "Benutzername",
    "password": "Password",
    "Do you really want to run {cmd}?": "Wollen Sie wirklich {cmd} ausführen?",
    "{component_name} power cycle: {status}": "{component_name} Power Cycle: {status}",
    "{component_name} power off: {status}": "{component_name} Power Off: {status}",
    "{component_name} power off soft: {status}": "{component_name} Power Off Soft: {status}",
    "{component_name} power on: {status}": "{component_name} Power On: {status}",
    "{component_name} power reset: {status}": "{component_name} Power Reset: {status}",
    "{component_name} power status: {status}": "{component_name} Power Status: {status}",
    "{component_name}: {error}": "{component_name}: {error}"
  }
}
</i18n>
