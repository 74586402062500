<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("server") }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip top v-if="wireguardConfig == null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading || creatingConfig || showConfig"
            :loading="creatingConfig"
            v-on="on"
            @click="createConfig"
            icon
          >
            <v-icon>mdi-shield-key</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("create VPN config") }}</span>
      </v-tooltip>

      <v-dialog v-model="showConfig" width="80%" v-else>
        <template v-slot:activator="{ on: dialog, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-bind="attrs"
                v-on="{ ...tooltip, ...dialog }"
                :disabled="loading || creatingConfig || showConfig"
                :loading="loadingConfig"
                @click="showConfig = true"
                icon
              >
                <v-icon>mdi-script-text-key</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open VPN config") }}</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("VPN config") }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row align="start" justify="center">
              <v-col cols="12" align="start" justify="center">
                <p class="mt-4">
                  <span v-html="$t('VpnMsg')" />
                  <br />
                  <v-btn
                    class="mt-4"
                    href="https://www.wireguard.com/install/"
                    target="_blank"
                    link
                    small
                    text
                  >
                    https://www.wireguard.com/install/
                    <v-icon right small> mdi-open-in-new</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
            <v-row class="my-2" align="center" justify="center">
              <v-col cols="4" align="center" justify="center">
                <qr-code
                  :text="wireguardConfig.customer_config"
                  :size="220"
                  error-level="L"
                />
              </v-col>
              <v-col cols="8" align="center" justify="center">
                <v-textarea
                  id="customer_config"
                  class="mt-4"
                  v-model="wireguardConfig.customer_config"
                  append-icon="mdi-content-copy"
                  @click:append="copyConfig"
                  dense
                  readonly
                  outlined
                  auto-grow
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="showConfig = false">
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="services-oob-overview" />
    </v-toolbar>

    <v-card-text>
      <v-text-field
        v-model="search"
        append-outer-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        :disabled="loading"
        :clearable="!isMobile"
        v-bind:label="$t('search')"
        single-line
        hide-details
      />

      <v-data-table
        :headers="headers"
        :items="systemsList"
        item-key="id"
        sort-by="name"
        :loading="loading"
        :search="search"
        :hide-default-footer="systemsList.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:item.customer_descr="{ item }">
          <v-edit-dialog
            :return-value.sync="item.customer_descr"
            :cancel-text="$t('cancel')"
            :save-text="$t('save')"
            @save="updateSystem(item)"
            large
          >
            <div class="editable">
              <v-icon small class="mr-1">mdi-pencil-outline</v-icon>
              <div>{{ item.customer_descr ? item.customer_descr : "-" }}</div>
            </div>
            <template v-slot:input>
              <v-text-field
                v-model="item.customer_descr"
                :label="$t('description')"
                single-line
                counter
              />
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.components="{ item }">
          <template v-for="(comp, idx) in components[item.id]">
            <oob-component
              :value="comp"
              :key="idx"
              :staff-only="item.staff_only"
              auto-load
            />
          </template>
        </template>

        <template v-slot:item.created="{ item }">
          <span class="monospace"> {{ formatDateTime(item.created) }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import OobComponent from "@/components/services/OobComponent";
import copyToClipboard from "@/utils/mixins/copyToClipboard";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "Oob",
  mixins: [copyToClipboard, formatDateTime, isMobile],
  components: {
    OobComponent,
    Wiki,
  },
  data: () => ({
    loadingSystems: false,
    loadingComponents: false,
    loadingConfig: false,
    creatingConfig: false,
    showConfig: false,
    systems: {},
    components: {},
    wireguardConfig: null,
    search: "",
  }),
  computed: {
    headers() {
      return [
        { text: this.$i18n.t("name"), value: "name" },
        { text: this.$i18n.t("description"), value: "customer_descr" },
        {
          text: this.$i18n.t("components"),
          value: "components",
          sortable: false,
        },
        { text: this.$i18n.t("created"), value: "created" },
      ];
    },
    loading() {
      return (
        this.loadingSystems || this.loadingComponents || this.loadingConfig
      );
    },
    systemsList() {
      return Object.values(this.systems);
    },
    componentList() {
      return Object.values(this.components);
    },
  },
  methods: {
    copyConfig() {
      this.copyToClipboard("customer_config", this.$t("config copied"));
    },
    getSystems() {
      var that = this;
      that.loadingSystems = true;
      this.$http
        .get("services/oob/systems")
        .then((response) => {
          var systems = {};
          response.data.forEach(function (system) {
            systems[system.id] = system;
          });
          that.systems = systems;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingSystems = false;
        });
    },
    updateSystem(system) {
      var that = this;
      that.loadingSystems = true;
      this.$http
        .patch("services/oob/systems/" + system.id, {
          customer_descr: system.customer_descr,
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingSystems = false;
        });
    },
    getComponents() {
      var that = this;
      that.loadingComponents = true;
      this.$http
        .get("services/oob/ipmi-components")
        .then((response) => {
          var components = {};
          response.data.forEach(function (comp) {
            if (components[comp.system_id] == null)
              components[comp.system_id] = [];
            components[comp.system_id].push(comp);
          });
          that.components = components;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingComponents = false;
        });
    },
    getConfig() {
      var that = this;
      that.loadingConfig = true;
      this.$http
        .get("services/oob/wireguard-keys")
        .then((response) => {
          if (response.data.length > 0)
            that.$http
              .get("services/oob/wireguard-keys/" + response.data[0].id)
              .then((response) => {
                that.wireguardConfig = response.data;
              })
              .catch((err) => {
                that.$store.commit("setSystemError", {
                  msg: err.message,
                  title: err.title,
                });
              })
              .finally(function () {
                that.loadingConfig = false;
              });
          else that.loadingConfig = false;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
          that.loadingConfig = false;
        });
    },
    createConfig() {
      var that = this;
      that.creatingConfig = true;
      this.$http
        .post("services/oob/wireguard-keys")
        .then((response) => {
          that.getConfig();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.creatingConfig = false;
        });
    },
    getItems() {
      this.getSystems();
      this.getComponents();
    },
  },
  mounted() {
    this.getItems();
    this.getConfig();
  },
};
</script>

<i18n>
{
  "en": {
    "reload": "reload",
    "open VPN config": "open WireGuard VPN config",
    "create VPN config": "create WireGuard VPN config",
    "server": "Server",
    "name": "Name",
    "description": "Description",
    "created": "Created",
    "components": "Components",
    "VPN config": "VPN config",
    "public key": "Public key",
    "IP address": "IP address",
    "config": "Config",
    "close": "Close",
    "cancel": "Cancel",
    "save": "Save",
    "config copied": "config copied",
    "VpnMsg": "To access your systems, you need to open a <b>Wireguard VPN</b>. To do this, use the following configuration. On mobile devices, you can simply install the Wireguard VPN app and create a new VPN via QR code."
  },
  "de": {
    "reload": "aktualisieren",
    "open VPN config": "WireGuard VPN Konfiguration öffnen",
    "create VPN config": "WireGuard VPN Konfiguration anlegen",
    "server": "Server",
    "name": "Name",
    "description": "Beschreibung",
    "created": "Angelegt",
    "components": "Komponenten",
    "VPN config": "VPN Konfiguration",
    "public key": "Public Key",
    "IP address": "IP Adresse",
    "config": "Konfiguration",
    "close": "Schliessen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "config copied": "Konfiguration kopiert",
    "VpnMsg": "Um auf Ihre Systeme zugreifen zu können, müssen Sie einen <b>Wireguard VPN</b> öffnen. Benutzen Sie dazu die folgende Konfiguration. Auf mobilen Geräten können Sie sich einfach die Wireguard VPN App installieren und einen neuen VPN via QR Code anlegen."
  }
}
</i18n>

<style scoped>
.editable {
  display: flex;
  align-items: center;
}
</style>
